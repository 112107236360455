import alertify from "alertifyjs";
import { demoJSON } from "./DataModel";


import 'jspdf-autotable';


export default class EmailModel {

  static sendCurriculumToEmail = async (pdfBlob, fileName, email, type ) => {
    if(!email) {
      return;
    }
    const reader = new FileReader();

    reader.onloadend = async () => {
        const base64data = reader.result.split(',')[1]; // Get base64 encoded file
        const body = JSON.stringify({
            email: email,
            fileName: fileName,
            type: type,
            file: base64data // Add base64 encoded file to the request body
        });

        try {
          // const url = 'http://127.0.0.1:5001/newlessonplan-prod/us-central1/emailCurriculum';
            const url = `https://us-central1-newlessonplan-prod.cloudfunctions.net/emailCurriculum`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body, // Send JSON body with base64 file
            });

            if (response.ok) {
              //  alertify.alert('Success', 'Curriculum was sent to the supplied email successfully!');
            } 
        } catch (error) {
          
        }
    };

    reader.readAsDataURL(pdfBlob); // Convert Blob to base64
};



static sendOrderEmail = async (invoiceBlob, curriculumBlob, invoiceFileName, curriculumFileName, email, orderNumber, invoiceType, curriculumType, emailHtml) => {
  if (!email || !orderNumber || !invoiceBlob || !curriculumBlob ||!emailHtml) {
      return;
  }

  const readFileAsBase64 = (fileBlob) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(fileBlob);
      });
  };

  try {
      const [invoiceBase64, curriculumBase64] = await Promise.all([
          readFileAsBase64(invoiceBlob),
          readFileAsBase64(curriculumBlob),
      ]);

      const body = JSON.stringify({
          email,
          orderNumber,
          emailHtml,
          invoiceFile: {
              content: invoiceBase64,
              filename: invoiceFileName,
              type: invoiceType,
          },
          curriculumFile: {
              content: curriculumBase64,
              filename: curriculumFileName,
              type: curriculumType,
          },
      });
      const prefix =  `https://us-central1-newlessonplan-prod.cloudfunctions.net`
      const url = `${prefix}/emailOrderFilesV3`;
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body,
      });

      if (response.ok) {
        return true;
      } else {
         // console.error('Failed to send email:', response.statusText);
          return false;
      }
  } catch (error) {
      // console.error('Error while sending curriculum and invoice:', error);
      return false;
  }
};


}
