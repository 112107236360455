import React, { useState, useEffect } from "react";
import "./components/KitGrid.css";
import { FormButton, showMagniAlert } from "./components/formButton";
import { useParams } from "react-router-dom";
import LearningKitsModel from "./utils/LearningKitsModel";
import { ALLOWED_REFERRAL_CODES, getSetKitsGlobal, GRADE, SAVE_ITEM_KEY, SUBJECT } from "./constants";
import _ from 'lodash'
import { FormInput } from "./components/formInput";
import { getKitInvoiceHtmlTemplate } from "./emailTemplates/kitInvoiceTemplate";
import PDFDocModel from "./pdfDocModel";
import alertify from "alertifyjs";
import { ScreenContainer } from "./App";
import { FeaturesList } from "./Home";
import { validateEmail } from "./NewCurriculum";
import { FormSelect } from "./components/formSelect";
import { LoadingSkeleton } from "./components/LoadingSkeleton";
import SwitchControl from "./components/SwitchControl";
import { FormCheckbox } from "./components/formCheckbox";


export const KitCheckoutForm = ({materials, _subject = '', checkoutEnabled = true,  total, kit = [{description: '', details: {shipping: 0, tax: 0, total: 0}}], onBack = () => {}, noHeader = false}) => {


const [subject, setSubject] = React.useState(_subject)
const [loading, setLoading] = React.useState('')
  const [studentName, setStudentName] = React.useState('')
  const [applicationNumber, setApplicationNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [streetAddress, setStreetAddress] = React.useState('')
  const [apt, setApt] = React.useState('')
  const [city, setCity] = React.useState('')
  const [state, setState] = React.useState('AZ')
  const [zip, setZip] = React.useState('')
  const [grade, setGrade] = React.useState('')
  const [getAsJson, setGetAsJson] = React.useState(false)
const [isSpecialNeeds, setIsSpecialNeeds] = React.useState(false)
  
  const [referralCode, setReferralCode] = React.useState('')

  const [showShippingInfo, setShowShippingInfo] = React.useState(false);


  const onCheckout = () => {
    // if((!studentName?.length || !applicationNumber?.length) && !getAsJson) {
    //   alertify.alert("Missing Info", "ESA requires student name and application number for curriculums. If you don't want to provide them, check off the checkbox right below Student name.")
    //   return;
    // }
    // if(!showShippingInfo) {
     
    //   if(!grade?.length) {
    //     alertify.alert("Grade Required", "Grade level is required to generate the supplemental curriculum.")
    //     return;
    //   }

    //   if(!subject?.length) {
    //     alertify.alert("Subject Required", "Subject is required to generate the supplemental curriculum.")
    //     return;
    //   }
    //   setShowShippingInfo(true);
    //   return;
    // }

    if(!showShippingInfo) {
      setShowShippingInfo(true);
      return;
    }
  
    if(!validateEmail(email)) {
      alertify.alert("Invalid Email", "ESA requires your email to review/approve purchases. Please provide a valid email to continue.")
      return;
    }

    if(!city?.length || !state?.length || !zip?.length || !streetAddress?.length || !email?.length ) {
      alertify.alert("Missing Info", "Please fill out all fields as they're required by ESA for approval.")
      return;
    }
    
    if(!checkoutEnabled) {
      setTimeout(() => {
        onCheckout()
      }, 500);
      return;
    }
    performCheckout();
  }


  const performCheckout = async () => {
    
    setLoading(true)
    if(!kit?.title) {
      kit.title = `${subject}`
    }
    const enteredCode = referralCode || ''
    const isValidCode = await LearningKitsModel.checkRCode(enteredCode);
    const code = isValidCode ? enteredCode : 'NLP' 
    const invoiceNumber = LearningKitsModel.generateUniqueInvoiceNumber(code)
    const html = getKitInvoiceHtmlTemplate({invoiceNumber: invoiceNumber, issueDate: formatDate(), billToEmail: email, billToName: studentName,
      kit: kit, billToAddress: `${streetAddress} ${apt?.length ? '#'+apt : ''}, ${city} ${state} ${zip}`,

    })

    // admin html
    const adminHtml = getKitInvoiceHtmlTemplate({invoiceNumber: invoiceNumber, issueDate: formatDate(), billToEmail: email, billToName: studentName, isAdmin: true,
      kit: kit, billToAddress: `${streetAddress} ${apt?.length ? '#'+apt : ''}, ${city} ${state} ${zip}`,

    })

    // generate curriculum and invoice
    const data = {studentName: studentName, applicationNumber: applicationNumber,
      subject: subject, getAsJson: getAsJson,
      grade: grade, materials:materials, 
      adminHtml: adminHtml, 
      html: html, isKit: true,invoiceNumber: invoiceNumber,
        email: email};
       if(isSpecialNeeds) {
        data.disability = 'Special Needs'
       }
       if(!studentName?.length || !grade?.length || !applicationNumber?.length) {
        data.skipCurriculum = true;
       }
       setLoading(false)
    //save data 
    sessionStorage.setItem(SAVE_ITEM_KEY, JSON.stringify(data))
     window.location.href = '/success'
  }

  const renderForm = () => {
    return (
      <div className="p-6">
        {
          showShippingInfo ? 
          <h3 className="text-4xl text-black-500 mb-2 fw-bold text-2xl">
            Shipping info
          </h3>
          :
          <div>
             <h3 className="text-4xl text-black-500 mb-2 fw-bold text-2xl">
            Curriculum Info
          </h3>
          <h3 className="text-md text-gray-500 mb-6 fw-bold text-2xl">
           
          <span className=" text-orange-500">Hit next if you have your own curriculum</span>
        </h3>
        </div>
        }

        <div className="mt-5 ">
     {showShippingInfo ? null :      <div>
          {getAsJson ? null : (
            <div className="row">
              <div className="col">
                <FormInput
                  label="Student Name"
                  text={studentName}
                  onTextChange={(e) => setStudentName(e)}
                  autoFocus
                />
              </div>
              <div className="col">
                <FormInput
                  label="ESA Application Number"
                  text={applicationNumber}
                  onTextChange={(e) => setApplicationNumber(e)}
                />
              </div>
            </div>
          )}
  
          {1==1 ? null : <div className="col-md-12 flex rounded m-4">
            <FormCheckbox
              label="Click if you don't want to provide student name/application number"
              value={getAsJson}
              onChange={(v) => {
                setApplicationNumber('');
                setStudentName('');
                setGetAsJson(v);
              }}
            />
  
            <div
              className="underline text-left"
              style={{ textDecorationColor: 'gray' }}
              onClick={() => {
                alertify.alert(
                  'Info',
                  `
                  If you provide name/application ID, you will get a PDF that you simply drag and drop onto ClassWallet.
                  But if you prefer to add student name and application number yourself, we will give you a Word document that you can edit, save, and then drop onto ClassWallet.
                  `
                );
              }}
            >
              <span className="text-gray-500" style={{ fontSize: 14, color: 'gray' }}>
                What's the difference?
              </span>
            </div>
          </div>}
  
         
  
          <div className="row align-items-center">
  <div className="col">
    <FormSelect
      className=""
      _options={GRADE.map((f) => ({ label: f, value: f }))}
      label="Grade"
      onSelect={(e) => setGrade(e)}
      placeholder="Select grade..."
    />
  </div>
  <div className="col d-flex align-items-center mt-4">
    <SwitchControl
      checked={isSpecialNeeds}
      onToggle={() => setIsSpecialNeeds(!isSpecialNeeds)}
      label="Special needs student?"
    />
  </div>
</div>
</div>}

         
          <hr className="my-6 border-gray-300" />
  
          {showShippingInfo ? (
            <div>
                 <div className="mt-4"> 
          <FormInput
                label="ESA Email"
                text={email}
                autoFocus
                onTextChange={(e) => setEmail(e)}
              />
           
          </div>
              <div className="row">
                <div className="col">
                  <FormInput
                    label="Street Address"
                    text={streetAddress}
                    onTextChange={(e) => setStreetAddress(e)}
                  />
                </div>
                <div className="col">
                  <FormInput
                    label="Apartment/Suite (Optional)"
                    text={apt}
                    onTextChange={(e) => setApt(e)}
                  />
                </div>
              </div>
  
              <div className="row">
                <div className="col">
                  <FormInput
                    label="City"
                    text={city}
                    onTextChange={(e) => setCity(e)}
                  />
                </div>
                <div className="col">
                  <FormInput
                    label="State"
                    text={state}
                    onTextChange={(e) => setState(e)}
                  />
                </div>
                <div className="col">
                  <FormInput
                    label="Zip Code"
                    maxLength={5}
                    text={zip}
                    onTextChange={(e) => setZip(e)}
                  />
                </div>
              </div>
              <hr />
            </div>
          ) : null}
  
          {showShippingInfo ? (
            <h4 className="text-left text-lg mt-4 px-4">
              <span className="font-bold text-purple-600">Total: ${total}</span>
            </h4>
          ) : null}
  
          <div className="bg-gray-50 p-4 rounded-lg d-flex gap-2">
            <FormButton
              label={
                loading
                  ? 'Loading...'
                  : !showShippingInfo
                  ? 'Next: Shipping Info'
                  : 'Generate Invoice for ClassWallet'
              }
              isPrimary
              isFullWidth
              loading={loading}
              onClick={onCheckout}
            />
          </div>
        </div>
      </div>
    );
  };
  


  const renderCheckoutForm = () => {

    if(noHeader) {
      return renderForm()
    }

    return( 
      
      <ScreenContainer title="Checkout" onBack={onBack}>
        
        {renderForm()}
       
     </ScreenContainer>
          )
  }



  
    return renderCheckoutForm()
  
};

const formatDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${month}-${day}-${year}`;
};

