import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/tailwind.css";
import { FormInput } from "./components/formInput";
import { jsPDF } from "jspdf";
import ReCAPTCHA from "react-google-recaptcha";
import { MainPage } from "./Home";
import { NewCurriculum } from "./NewCurriculum";
import { About } from "./About";
import { Contact } from "./Contact";
import { Home } from "./Home";
import { AppRoutes } from "./AppRoutes";
import 'bootstrap-icons/font/bootstrap-icons.css';
import SocialProofWidget from "./components/SocialProof";
import { TopNewCurriculumButton } from "./components/TopNewCurrButton";
import { CouponBanner } from "./utils/vGenerator";
import { FormButton } from "./components/formButton";
import LearningKitsModel from "./utils/LearningKitsModel";
import { getSetKitsGlobal } from "./constants";
 
global.isDev = window.location.href.includes('localhost')
global.serverName = 'newlessonplan-prod'
global.slightAttentionColor ="rgb(252 177 101)"//  '#4ab6d9' //'#f6b63a'//
global.orange = '#f6b63a';
global.green = '#7bbf4b'

function App() {
  const [step, setStep] = React.useState(0);


  React.useEffect(() => {
    LearningKitsModel.getLearningKitsFromDb().then(f => {
      getSetKitsGlobal(f)
      
    })
  }, [])

  return (<>
    <div className="bg-white" style={{fontFamily:''}}>
      <TopNewCurriculumButton/>
   

      </div>

   
        <div className=" pt-[120px] md:pt-[200px] "/>
        <AppRoutes/>
        <SocialProofWidget/>
      <Footer/>
    </>
  );
}


export default App;


const FacebookPage = () => {
  return(
    <div className="d-flex justify-content-center text-center align-items-center w-100" >
      <img src={require('./assets/images/facebook_icon.png')} style={{height: 30, marginRight: 10, alignSelf:'center'}}/>
      <a href="https://www.facebook.com/profile.php?id=61561939816406" className="text-center underline" >Follow us on Facebook</a>
  </div>
  
  )
}


const PrivacyBadge = () => {
  return(    <div className='flex flex-row items-center justify-center text-left max-w-[50%] mx-auto p-4 border rounded m-10 '
  style={{maxWidth: window.innerWidth < 800 ? '90%' : '60%'}}
  >
    {/* <i class="bi bi-shield-check fs-2"></i> */}
    <img src={require('./assets/images/badge.png')} className='h-[80px] mb-2'/>
    <p className=' px-4'>
     <span className=""> AZ Parents Owned. Proud supporters of ESA.<br/> 
      Not affiliated with SOS. We do not share or store your information.
      </span> 
    </p>
  </div>)
}

const Footer = () => {
  return (
    <div className="pt-20">
         <div
      style={{
        backgroundColor: '#eeeeee',
        color: '#333333',
       
        padding: '20px',
        marginTop: '40px',
      }}
    >
      <footer style={{ textAlign: 'center' }}>
        <div style={{ paddingBottom: '20px' }}>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '40px',
              paddingTop: '20px',
            }}
          >
            <div>
              <a href="/" style={linkStyle}>
                Home
              </a>
            </div>
            <div>
              <a href="/about" style={linkStyle}>
                About Us
              </a>
            </div>
            <div>
              <a href="/vouchers-purchase" style={linkStyle}>
                Vouchers
              </a>
            </div>
            {/* <div>
              <a href="/referral-code" style={linkStyle}>
                Make Money With Us
              </a>
            </div> */}
            <div>
              <a href="/contact" style={linkStyle}>
                Contact
              </a>
            </div>
            <div>
              <a href="/privacy-policy" style={linkStyle}>
                Privacy Policy
              </a>
            </div>
          </div>
        </div>

        <hr style={{ border: '0.5px solid #ddd', margin: '20px 0' }} />

        <div style={{ marginBottom: '20px', margin: '0 auto' }}>
          <p style={{ fontSize: '0.9rem', color: '#666' }}>
            Note: we cannot guarantee approval. However, we'll create a curriculum that adheres to
            the ESA guidelines and that we use for our kids to get approved.
          </p>
          <PrivacyBadge />
        </div>

        <div style={{ padding: '10px 0' }}>
          <FacebookPage />
        </div>

        <div
          style={{
            fontSize: '0.85rem',
          
            padding: '10px',
            borderTop: '1px solid #ddd',
          }}
        >
          <span>© 2024 </span>
          <a href="https://newlessonplan.com" style={{ color: '#333', textDecoration: 'none', fontWeight: 'bold' }}>
            NewLessonPlan.com
          </a>
          <span> All Rights Reserved.</span>
        </div>
      </footer>
    </div>
    </div>
  );
};

const linkStyle = {
  color: '#333',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  textDecoration: 'none',
  transition: 'color 0.3s',
  textTransform: 'uppercase',
};


export const ScreenContainer = ({...props}) => {
  const navigate = useNavigate()
  return(  <div className=" min-h-screen py-10 px-4 pb-10">
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <header className={`${props?.bgColor ? props.bgColor : 'bg-purple-700'} text-white text-center py-6`}>
     {props.hideBackButton ? null : <div className="bi bi-arrow-left absolute lef-10 bg-transparent mx-4 md:text-xl cursor-pointer"
     onClick={() => props?.onBack ? props?.onBack?.() :  navigate(-1)}
     ></div>
      
            
            }
        <h2 className="md:text-2xl font-bold" style={{maxWidth: '80%', alignSelf:'center', textAlign:'center', margin: '0 auto'}}>
         {props?.title || ''}
        </h2>
        </header>
        {props.children}
        </div>
        </div>
        )
}