import React from "react";
import { ScreenContainer } from "./App";
import { FormInput } from "./components/formInput";
import { FormButton } from "./components/formButton";
import { callEmailSvc } from "./utils/vGenerator";
import { getExpeditedOrderNumberTemplate } from "./emailTemplates/voucherTemplate";
import { handleImageUpload } from "./constants";
import alertify from "alertifyjs";


const KEY = "expedited-order-success"

export const ExpediteOrder = () => {
  const [orderNumber, setOrderNumber] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState('');

  return (
    <ScreenContainer title="Limited Time Only">
      <div className="fs-2 fw-bold m-10">
        You can expedite your order to get your items shipped right away before
        ESA approval for a refundable fee. Will be refunded once ESA approves your order. {" "}
        <br/><br/><span className="text-success">Christmas Offer - Limited Time</span>
      </div>
      <div className="align-items-center text-left p-10">
        <FormInput
          text={orderNumber}
          onTextChange={(t) => setOrderNumber(t)}
          autoFocus
          label="Your NewLessonPlan Order Number (Starts with NLP-)"
        />

      <img src={imageUrl} className="w-[200px] rounded m-2" />

<div className="d-flex mb-10">
      <div className="text-left  fw-bold mr-4 ">Upload a screenshot of your ESA order</div>
        <input
        
          type="file"
          onChange={async e => {
            const result = await handleImageUpload(e)
            setImageUrl(result)
          }}
        />
</div>

        <FormButton label="Pay refundable fee" 
        isPrimary isFullWidth
        onClick={() => {
          if(orderNumber?.length && imageUrl?.length) {
            localStorage.setItem(KEY, `${orderNumber}##${imageUrl}`)
            window.location.href = `https://buy.stripe.com/7sI7tm3qy64J1Gw14a`
          } else {
            alertify.alert('Required', 'Order number and screenshots are required.')
          }

        }} />
      </div>
    </ScreenContainer>
  );
};



export const ExpediteOrderSuccess = () => {
 const [did, setDid] = React.useState(false)
 
  React.useEffect(() => {
    //check order number
    const d = localStorage.getItem(KEY)
    if(d?.length && !global.isWorking) {
      global.isWorking = true;
      callEmailSvc('support@newlessonplan.com', 'aj.tx1986@gmail.com', 'EXPEDITED ORDER FEE PAID', getExpeditedOrderNumberTemplate(d))
      
      localStorage.removeItem(KEY)
      global.isWorking = false;
    }
  }, [])
  return (
    <ScreenContainer title="Success" onBack={() => {
      window.location.href = '/'
    }}>
      <div className="fs-2 m-10 text-center">
        
        <span className="text-success">Thanks. Will match your order number and get your items shipped right away.{" "}</span>
      </div>
      <div className="align-items-center text-center p-10">
       
      </div>
    </ScreenContainer>
  );
};

