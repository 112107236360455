
export const GET_URL_PREFIX = (isDev = false) =>
isDev ?  "http://127.0.0.1:5001/newlessonplan-prod/us-central1/"
: "https://us-central1-newlessonplan-prod.cloudfunctions.net";


export const createNewOrder = async (order = {
    number: "",
    HTML: "",
    email: "",
    isComplete: false,
    isShipped: false,
    amazonOrderNumber: "",
    datePlaced: "",
    dateFulfilled: "",
}) => {

    await fetch(`${GET_URL_PREFIX(false)}/handleOrders`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(order),
    });
}
