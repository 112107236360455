import React from 'react';
import {ScreenContainer} from '../App'
import { CreateLearningKit } from './CreateLearningKit';

export const KitAdmin = () => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    React.useEffect(() => {
        const authStatus = localStorage.getItem('isAuth');
        if (authStatus === 'true') {
          setIsAuthenticated(true);
        }
      }, []);
  
      if(!isAuthenticated) {

        return <AuthChecker />
      }
    return (
      <div>
        <ScreenContainer title={'Kit Admin'}>
          <CreateLearningKit />
        </ScreenContainer>
      </div>
    );
  };
  


  export const AuthChecker = ({onAuth = () => {}}) => {

    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
  
    // Function to handle password validation
    const handlePasswordSubmit = async () => {
        setLoading(true)
        const response =  await fetch(`https://us-central1-newlessonplan-prod.cloudfunctions.net/checkAd`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({p: password}), // Send JSON body with base64 file
        });
           
            
        setLoading(false)
      if (response?.ok) {
        localStorage.setItem('isAuth', 'true');
        localStorage.setItem('IS_ORDER_MANGER', 'true')
        setIsAuthenticated(true);
        setError('');
        window.location.reload();
      } else {
        setError('Invalid password. Please try again.');
      }
    };
  
    if (!isAuthenticated) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <div className="bg-white p-6 rounded shadow-md w-80">
            <h2 className="text-xl font-semibold mb-4 text-gray-700">Admin Login</h2>
            <input
              type="password"
              className="border border-gray-300 rounded p-2 w-full mb-4"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
            <button
              className="bg-blue-500 text-white rounded px-4 py-2 w-full hover:bg-blue-600 transition"
              onClick={handlePasswordSubmit}
            >
            {loading ? 'Loading....' : 'Submit'}
            </button>
          </div>
        </div>
      );
    }
  }