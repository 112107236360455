import React, { useState, useEffect } from "react";
import { FormInput } from "../components/formInput";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FormButton } from "../components/formButton";
import { FormCheckbox } from "../components/formCheckbox";
import { ModalWrapper } from "./ManagerOrders";
import { ScreenContainer } from "../App";

export const CreateLearningKit = () => {
    const [loading, setLoading] = React.useState(false);
  const [kits, setKits] = useState([]);
  const [kitForm, setKitForm] = useState({
    imgUrl: "",
    title: "",
    description: "",
    id: "",
    details: {},
    isSpecialNeeds: false,
    subject: '',
    isPublished: false,
    shipping: 50,
    margin: null,
    category: ''
  });
  const [editingKitId, setEditingKitId] = useState(null);
  const isDev = false;
  const URL_PREFIX = isDev ?  "http://127.0.0.1:5001/newlessonplan-prod/us-central1" : 'https://us-central1-newlessonplan-prod.cloudfunctions.net';
  // Fetch kits from Firebase
  const fetchKits = async () => {
    setLoading(true)
    const response = await fetch(`${URL_PREFIX}/handleKits`);
    const data = await response.json();
    setKits(data);
    setLoading(false)
  };

  useEffect(() => {
    fetchKits();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target 
    setKitForm({
      ...kitForm,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle image upload
  const handleImageUpload = async (e) => {
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const base64Data = reader.result.split(",")[1]; // Extract base64 data
      const mimeType = file.type;

      try {
        const response = await fetch(`${URL_PREFIX}/uploadImage`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imageBase64: base64Data,
            mimeType,
          }),
        });

        if (!response.ok) {
          throw new Error(`Image upload failed: ${response.statusText}`);
        }

        const { imageUrl } = await response.json();
        setLoading(false)
        setKitForm({ ...kitForm, imgUrl: imageUrl });
      } catch (error) {
        setLoading(false)
        alert("Image upload error:", error);
      }
    };

    reader.readAsDataURL(file);
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      if (editingKitId) {
        await fetch(`${URL_PREFIX}/handleKits`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...kitForm, id: editingKitId, lastUpdated: new Date().getTime() }),
        });
      } else {
        await fetch(`${URL_PREFIX}/handleKits`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({...kitForm, createdAt: new Date().getTime()}),
        });
      }

      setKitForm({
        imgUrl: "",
        title: "",
        description: "",
        id: "",
        details: {},
        isSpecialNeeds: false,
      });
      setEditingKitId(null);
      fetchKits();
      setShowAddKit(false)
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  // Handle deleting a kit
  const handleDelete = async (kitId) => {
    try {
      await fetch(`${URL_PREFIX}/handleKits?id=${kitId}`, {
        method: "DELETE",
      });
      fetchKits();
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  // Handle editing a kit
  const handleEdit = (kit) => {
    setShowAddKit(true)
    setKitForm(kit);
    setEditingKitId(kit.id);
  };

  
  const cl = "rounded p-2 w-100 m-2 border-1 mb-10"

  const [showAddKit, setShowAddKit] = React.useState(false)


  const renderAddKit = () => {

    if(!showAddKit) {
        return (<div className="m-10">
            <FormButton label="+ Add Kit"
            isPrimary isFullWidth
            onClick={() => setShowAddKit(!showAddKit)}
            />
        </div>)
    }

    return(<div >
    <ModalWrapper>
      <ScreenContainer title="Manage Learning Kits (ADMIN)" onBack={() => setShowAddKit(false)}>
<div className="mb-20 px-20" >

      <div className="m-3"/>
Title
      <input
            className={cl}
        type="text"
        name="title"
        value={kitForm.title}
        placeholder="Title (ESA WILL SEE THIS)"
        onChange={handleInputChange}
      />
      <div className="m-3"/>
      Description
      <input
      isRaw
      className={cl}
      isTextArea
        name="description"
        value={kitForm.description}
        placeholder="Description (ESA WILL SEE THIS)"
        onChange={handleInputChange}
      ></input>

<div className="m-3"/>
      Category
      <input
      isRaw
      className={cl}
      isTextArea
        name="category"
        value={kitForm.category}
        placeholder="Category"
        onChange={handleInputChange}
      ></input>

<hr className="mt-2"/>
    <img src={kitForm.imgUrl} className="w-[100px] rounded m-2" />
      <input
      className={cl}
        type="file"
        onChange={handleImageUpload}
      />
       <div className="mt-5"/>
       <input
       
        type="checkbox"
        name="isSpecialNeeds"
        checked={kitForm.isSpecialNeeds}
        onChange={handleInputChange}
      />
      &nbsp; Special Needs
      <div className="m-3"/>
      Subject
      <input
      isRaw
      className={cl}
      isTextArea
        name="subject"
        value={kitForm.subject}
        placeholder="Subject"
        onChange={handleInputChange}
      ></input>
<div className="m-1"/>
Shipping
<input
      isRaw
      className={cl}
      isTextArea
        name="shipping"
        value={kitForm.shipping}
        placeholder="Shipping"
        onChange={handleInputChange}
      ></input>


<div className="m-1"/>
Profit Margin
<input
      isRaw
      className={cl}
      isTextArea
        name="margin"
        value={kitForm.margin}
        placeholder="margin"
        onChange={handleInputChange}
      ></input>
      
<div className="m-3"/>
<input
       
       type="checkbox"
       name="isPublished"
       checked={kitForm.isPublished}
       onChange={handleInputChange}
     />
     &nbsp; Show in PRODUCTION (PAY ATTENTION HERE)

      <div className="mt-5"/>
      <FormButton isPrimary isFullWidth
      label={editingKitId ? "Update Kit" : "Add Kit"}
      onClick={handleSubmit} />
    

    </div>
    </ScreenContainer>
    </ModalWrapper>
    </div>
    )

  }

  return (
    <div className="px-10 pb-20">
        {
            loading ? <div className="text-success border-2 text-center m-10 p-2 fs-3">Loading....</div> : ''
        }
        {renderAddKit()}
      <ul>
        {kits.map((kit) => (
          <li key={kit.id} className="w-100 border-5 p-2">
            {
              kit?.isPublished ? <div className="badge bg-danger">PRODUCTION</div>  : <div className="badge bg-dark">DRAFT</div>
            }
            <div className="d-flex  cursor-pointer mb-10"
            onClick={() => window.location.href ='/katy-products/' + kit.id + '/' + kit?.title}
            >
            <img src={kit.imgUrl} alt={kit.title}  className="bg-black object-fit-cover border-3"
            
            style={{height: 150, width: 150}}/>
            <div className="p-2 ">
            <h3 className="fs-2 fw-bold underline mb-2">{kit.title}</h3>
            <p>{kit.description}</p>
            </div>
            </div>
          
            <div className="d-flex w-100 gap-3">
            <FormButton label="Edit" onClick={() => handleEdit(kit)} isFullWidth/>
            <FormButton isDanger label="Delete" superExtraClass=" bg-danger" onClick={() => handleDelete(kit.id)} isFullWidth />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
