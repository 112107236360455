/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import App from './App'
import { Home } from './Home'
import { About } from './About'
import { Contact } from './Contact'
import { NewCurriculum } from './NewCurriculum'
import { Help } from './help'
import { Success } from './Success'
import { PrivacyPolicy } from './PrivacyPolicy'
import { Vouchers } from './Vouchers'
import { VoucherSuccess } from './VoucherSuccess'
import { HowReimbursementWorks } from './HowReimbursementWorks'
import { LearningKits } from './LearningKits'
import { LearningKitDetails } from './LearningKitDetails'
import { HowDirectPayWorks } from './HowDirectPayWorks'
import { ReferralCodeLanding } from './ReferralCodeLanding'
import { KitAdmin } from './AdminControls/KitAdmin'
import { CreateProduct } from './AdminControls/CreateProduct'
import { AmazonManager } from './AmazonManager'
import { CustomOrder } from './CustomOrder'
import { ManageOrders } from './AdminControls/ManagerOrders'
import { ExpediteOrder, ExpediteOrderSuccess } from './expediteOrder'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {BASE_URL} = '/'

const AppRoutes = () => {
  


  return (
    <BrowserRouter basename={BASE_URL}>
      <Routes>
                <Route element={<App />} />
              <Route path='/' element={<Home />} />         
              <Route path='about' element={<About />} />    
              <Route path='help' element={<Help />} />         
              <Route path='contact' element={<Contact />} />
              <Route path='success' element={<Success />} />
              <Route path='new' element={<NewCurriculum />} />    
              <Route path='vouchers-purchase/*' element={<Vouchers />} />   
              <Route path='voucher-success/*' element={<VoucherSuccess />} />    
              <Route path='privacy-policy' element={<PrivacyPolicy />} />
              <Route path='learning-kits' element={<LearningKits />} />
              <Route path='kit-details/:id/:grade' element={<LearningKitDetails />} />
              
              <Route path='referral-code' element={<ReferralCodeLanding />} />
              <Route path='katy' element={<KitAdmin />} />
              <Route path='katy-products/:kitId/:kitName' element={<CreateProduct />} />
              <Route path='/build-my-kit' element={<AmazonManager/>} />
              <Route path='how-direct-pay-works' element={<HowDirectPayWorks />} />
              <Route path='/custom-order' element={<CustomOrder />} />
              <Route path='how-reimbursement-works' element={<HowReimbursementWorks />} />
              <Route path='/expedite-order' element={<ExpediteOrder />} />
              <Route path='/expedited-order-success' element={<ExpediteOrderSuccess />} />
              
              <Route path='/coco' element={<ManageOrders />} />
              <Route path='*' element={<Home />} />      


              
    
       
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
