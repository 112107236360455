import React from "react";
import {
  callCouponSvc,
  CouponBanner,
  generateUniqueCoupon,
  getAvailableVouchers,
} from "./utils/vGenerator";
import { COUPON_ITEM_KEY, GRADE } from "./constants";
import { FormInput } from "./components/formInput";
import { FormButton } from "./components/formButton";
import LearningKitsModel from "./utils/LearningKitsModel";
import "./components/KitGrid.css";
import { FormSelect } from "./components/formSelect";
import { ScreenContainer } from "./App";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { ApprovalGuaranteeBanner } from "./Home";

export const LearningKits = ({fromHome = false}) => {
  const [coupon, setCoupon] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [checkedBalance, setCheckedBalance] = React.useState(null);
  const [showBalanceCheck, setShowBalanceCheck] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [grade, setGrade] = React.useState('third')
  const [selectedKit, setSelectedKit] = React.useState(null)

  const [kits, setKits] = React.useState([])

  // categories
  const [selectedCategory, setSelectedCategory] = React.useState("All");
   // Update kits based on the selected category
    
    // Get unique categories from kits
    const categories = ["All", 'Special Needs', ...new Set(kits?.map(kit => kit.category?.length ? kit?.category : kit?.subject))];
  const [filteredKits, setFilteredKits] = React.useState(kits)
 
  
   React.useEffect(() => {
       // Handle filter logic
       const filtered =
       selectedCategory === "All"
         ? kits
         :
         selectedCategory === "Special Needs"
         ? kits?.filter(k => k?.title?.includes('Special Needs') || k?.isSpecialNeeds)
         : kits.filter(kit => kit.category === selectedCategory || kit?.subject === selectedCategory);
    setFilteredKits(filtered);
  }, [selectedCategory]);


  const navigate = useNavigate();
  React.useEffect(() => {
    sessionStorage.removeItem(COUPON_ITEM_KEY);

    if (window?.location?.href?.includes("check-balance")) {
      setShowBalanceCheck(true);
    }
  }, []);

  React.useEffect(() => {
    if(global.kits?.length) {
      const k = global?.kits?.cat(LearningKitsModel.getSpecialNeedsKits())
      setKits(k)
      setFilteredKits(k)
      return;
    }
    LearningKitsModel.getLearningKitsFromDb().then(k => {
      k = k.concat(LearningKitsModel.getSpecialNeedsKits())
      setKits(k)
      setFilteredKits(k)
    })
  }, [])

  const renderCopy = () => {

    if(fromHome) {
      return ( <div class="max-w-4xl mx-auto text-center m-20 mb-10"
        >
           
          <h2 class="text-3xl font-bold text-purple-700  ">
           
          </h2>
          </div>
          
          )
    }
    return ( <div class="max-w-4xl mx-auto text-center"
    >
       
      <h2 class="text-3xl font-bold text-purple-700  ">
        Shop Learning Kits 
      </h2>
      {fromHome ? <div className="m-10"></div>
       :  <div>
     

      <div className="mb-10 text-success mt-3">
        You'll get a free curriculum with all of our kits at checkout 🤗
      </div>
      </div>}
      </div>
      )
  };


  const [showFilters, setShowFilters] = React.useState(false);
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const renderFilter = () => {
    
    if(!showFilters && fromHome) {
      return (
        <div className="">
          <button
            className="px-4 py-2 bg-pink-400 text-white rounded-lg shadow hover:bg-pink-600"
            onClick={toggleFilters}
          >
            {showFilters ? 'Hide Filters' : 'Click to Shop By Category'}
          </button>
          
        </div>
      );
    }
    return (
      <div className="mb-6 flex flex-wrap justify-center gap-3">
        {categories?.filter(f=>f)?.map((category, index) => (
          <button
            key={index}
            onClick={() => {
              
              setSelectedCategory(category)
              if(category === 'All') {
                setKits(kits)
              }
            }}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === category
                ? "bg-purple-700 text-white"
                : "bg-gray-200 text-gray-700"
            } hover:bg-purple-500 hover:text-white transition-all`}
          >
            {category}
          </button>
        ))}
      </div>
    );
  };


  const renderContents = () => {

    return (<>
     <KitGrid kits={filteredKits} 
          fromHome={fromHome}
          onClick={(kit) =>{
            setSelectedKit(kit)
          //  setShowModal(true)
       
            window.location.href = `kit-details/${kit?.id}/${grade}`
          }}
          />
          

<hr/>


         

      {
        showModal ?  <div className=" fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50" style={{zIndex: 99999999}}>
        <div className="bg-white p-6 rounded-lg shadow-lg  ">
          <h2 className="text-lg font-bold mb-4">{`Select a grade to see your customized kit`}</h2>
          <FormSelect
          _options={GRADE.map(f => ({label: f, value:f}))}  onSelect={e => setGrade(e)}  placeholder="Select grade..."
          
            />
          <div className="d-flex gap-2">
        
<FormButton label="Cancel"  isFullWidth superExtraClass=" bg-gray-500 hover-bg-gray-500 hover:bg-gray-300" isPrimary
onClick={() => setShowModal(false)}
/>
<FormButton label="Go" isPrimary isFullWidth superExtraClass=" "

onClick={() => {
  if(!grade?.length) {
    return;
  }
    window.location.href = `kit-details/${selectedKit?.id}/${grade}`
}}
/>


            </div>
            <div className="m-2">
                </div>
            <small className="text-gray-400 pt-10">
            Kits are customized based on the student's grade level.
            </small>
          </div>

          

          </div>

          : null
     }
    </>)
  }

  if(fromHome) {
    return (<div className="mt-10">
      {renderFilter()}
      {renderContents()}
    </div>)
  }
  
  return (
   
      <div className=  {`md:m-${fromHome ? 0 : 10}`}>
        {renderCopy()}
        {renderFilter()}
        <ScreenContainer title="Exclusive & Rare Finds Kits"
        hideBackButton={fromHome}
        onBack={() => navigate('/')}
        
        >
          
         {renderContents()}
    </ScreenContainer>
    </div>
    
  );
};

export const Kit = ({ kit, index, onClick = () => {}, fromHome = false }) => {
 
  return (
    <div
    onClick={() => onClick(kit)}
    key={index}
    className={` mb-10 sm:w-[calc(50%-1rem)] mt-4 lg:w-[calc(${fromHome ? 35 : 25}%-1rem)] p-1 bg-gray-100 rounded-lg shadow-xl hover:shadow-lg transition-transform transform hover:-translate-y-1 border-1`}
  >
    {kit?.title?.includes('Special Needs') || kit?.isSpecialNeeds ? (
      <div className="bg-pink-400 absolute top-1 rounded-[30px] shadow-md text-white font-bold p-2">
        Special Needs ❤️
      </div>
    ) : null}
   <div className="w-full h-80 rounded-md flex items-center justify-center overflow-hidden bg-white">
  <img
    src={kit.imgUrl}
    alt={kit.title?.replace('Special Needs', '')}
    className="object-cover h-full w-auto rounded-[15px]"
  />
</div>

    <div className="text-center mt-5 pb-3">
      <h5 className="font-semibold text-gray-800">
        {kit.title?.replace('Special Needs - ', '')}
      </h5>
      {/* <p className="text-md text-gray-600 mt-1 text-left  p-2">
          {kit.description}
      </p> */}
    </div>
  </div>
  
  );
};

const KitGrid = ({ kits, onClick = () => {}, fromHome }) => {
  return (
    <div>
    <div className="flex flex-wrap gap-4 justify-center cursor-pointer">
      {kits?.map((kit, index) => (
        <Kit kit={kit} index={index} onClick={onClick} fromHome={fromHome} />
      ))}
    </div>
    </div>
  );
};

export default KitGrid;
