import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScreenContainer } from "../App";
import { FormButton, showMagniAlert } from "../components/formButton";
import { AuthChecker } from "./KitAdmin";
import _ from 'lodash';
import { callEmailSvc } from "../utils/vGenerator";
import { generateOrderEmail } from "../emailTemplates/orderPlacedEmail";

export const ManageOrders = ({}) => {
  const ordersPerPage = 25;
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCompleted, setFilterCompleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [currentHtml, setCurrentHtml] = React.useState(null)
    const [orders, setOrders] = useState([]);
    const [orderForm, setOrderForm] = useState({
        number: "",
        HTML: "",
        email: "",
        isComplete: false,
        isShipped: false,
        amazonOrderNumber: "",
        datePlaced: "",
        dateFulfilled: "",
        esaOrderNumber: "",
        profit: ''
    });
    const [editingOrderId, setEditingOrderId] = useState(null);
    const isDev = false;
    const URL_PREFIX = isDev
        ? "http://127.0.0.1:5001/newlessonplan-prod/us-central1/"
        : "https://us-central1-newlessonplan-prod.cloudfunctions.net";

    
    const fetchOrders = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${URL_PREFIX}/handleOrders`);
            let data = await response.json();
           
            setOrders(data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);


    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    React.useEffect(() => {
        const authStatus = localStorage.getItem('IS_ORDER_MANGER');
        if (authStatus === 'true') {
          setIsAuthenticated(true);
        }
      }, []);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setOrderForm({
            ...orderForm,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSearch = (e) => {
      setSearchQuery(e.target.value);
  };

  const handleToggleCompleted = () => {
      setFilterCompleted(!filterCompleted);
      setCurrentPage(1);
  };

  const sendOrderPlacedEmail = (order) => {
    const orderId = order?.number;
  
    if (!order.email || !orderId) {
      alert('Order ID or email missing');
      return;
    }
  
    // Function to show the modal and get the estimated delivery date
    const showDeliveryDateModal = () => {
      const deliveryDate = prompt("Please enter the estimated delivery date (e.g., Dec 17, 24):");
  
      if (!deliveryDate) {
        alert("Estimated delivery date is required.");
        return null;
      }
      return deliveryDate;
    };
  
    const estimatedDeliveryDate = showDeliveryDateModal();
  
    if (!estimatedDeliveryDate){
      alert('NOT SENDING, need delivery date, try again.')
      return; // Exit if the user cancels or enters an invalid date.
      }
  
    // Pass the estimated delivery date to generateOrderEmail
    const template = generateOrderEmail(orderId, estimatedDeliveryDate);
  
    const email = order.email; // Use the email from the order object.
    callEmailSvc(
      'support@newlessonplan.com',
      email,
      `${orderId} - Order Placed Confirmation`,
      template
    );
  
   updateOrder({ ...order, isShipped: true, isComplete: true });
    alert('Email sent to ' + email);
  };
  


  const renderHeader = () => {

    return(<div className="flex justify-between items-center mb-4">
      <input
          type="text"
          placeholder="Search by order number or email"
          value={searchQuery}
          onChange={handleSearch}
          className="border p-2 rounded w-1/3"
      />
      <button
          onClick={handleToggleCompleted}
          className="bg-blue-500 text-white px-4 py-2 rounded"
      >
          {filterCompleted ? "Show Active Orders" : "Show Completed Orders"}
      </button>
     
  </div>)
  }

  const filteredOrders = orders
  .filter(order =>
      (filterCompleted ? order.isComplete : !order.isComplete) &&
      (order.number.includes(searchQuery) || order.email.includes(searchQuery))
  );

const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
const paginatedOrders = filteredOrders.slice(
  (currentPage - 1) * ordersPerPage,
  currentPage * ordersPerPage
);
    // Handle form submission

    const updateOrder = async (order) => {
      if(!order?.id) {
        return;
      }
      await fetch(`${URL_PREFIX}/handleOrders?id=${order?.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(order),
    });
    fetchOrders();
    }

    const handleSubmit = async () => {


      if(!orderForm?.number?.length || !orderForm?.HTML?.length) {
        alert('enter order number and html')
        return;
      }
        try {
            setLoading(true);
            if (editingOrderId) {
                await fetch(`${URL_PREFIX}/handleOrders?id=${editingOrderId}`, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(orderForm),
                });
            } else {
                await fetch(`${URL_PREFIX}/handleOrders`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(orderForm),
                });
            }

            setOrderForm({
                number: "",
                HTML: "",
                email: "",
                isComplete: false,
                isShipped: false,
                amazonOrderNumber: "",
                datePlaced: "",
                dateFulfilled: "",
            });
            setEditingOrderId(null);
            fetchOrders();
            setShowAdd(false);
            setLoading(false);
        } catch (error) {
            console.error("Submission error:", error);
        }
    };


    const renderTable = () => {

      return(<div>
        {renderHeader()}
         <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Order #</th>
                            <th className="border border-gray-300 p-2">Email</th>
                            <th className="border border-gray-300 p-2">ESA Order #</th>
                            <th className="border border-gray-300 p-2">Amazon Order #</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedOrders.map(order => (
                            <tr
                                key={order.id}
                                className={
                                    order.isComplete
                                        ? "bg-green-700 text-white"
                                        : order.isShipped
                                        ? "bg-green-200"
                                        : ""
                                }
                            >
                                <td className="border border-gray-300 p-2 cursor-pointer
                                underline
                                "
                                onClick={() => {
                                  setCurrentHtml(order.HTML)
                                  setShowModal(true)
                                }}
                                >{order.number}</td>
                                <td className="border border-gray-300 p-2">{order.email}</td>
                                <td className="border border-gray-300 p-2">{order.esaOrderNumber}</td>
                                <td className="border border-gray-300 p-2 underline" style={{maxWidth: '100px'}}
                                onClick={() => {
                                  window.open(order.amazonOrderNumber, 'blank')
                                }}
                                >{order.amazonOrderNumber}</td>
                                <td className="border border-gray-300 p-2">
                                    {/* {!order.isShipped && (
                                        <button
                                            onClick={() => handleMarkShipped(order.id)}
                                            className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                                        >
                                            Mark as Shipped
                                        </button>
                                    )} */}
                                    {/* {!order.isComplete && (
                                        <button
                                            onClick={() => handleMarkComplete(order.id)}
                                            className="bg-green-500 text-white px-2 py-1 rounded mr-2"
                                        >
                                            Mark as Complete
                                        </button>
                                    )} */}

                                        {
                                          order?.isComplete ?  null

                                      : <div>
                                        <button
                                        onClick={() => handleEdit(order)}
                                        className="bg-orange-500 text-white px-2 py-1 rounded mr-2 mb-2"
                                    >
                                        Edit
                                    </button>

                                    <button
                                        onClick={() => handleDelete(order.id, order)}
                                        className="bg-red-500 text-white px-2 py-1 rounded mr-2"
                                    >
                                        Delete
                                    </button>
                                    <button
                                          onClick={() => sendOrderPlacedEmail(order)}
                                          className="bg-success text-white px-2 py-1 rounded mr-2"
                                      >
                                          Notify customer with 'order placed' 
                                      </button>
                                      </div>
                                        }
                                         

                                   
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-between items-center mt-4">
                    <button
                        onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                        disabled={currentPage === 1}
                        className="bg-gray-300 px-4 py-2 rounded"
                    >
                        Previous
                    </button>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                        disabled={currentPage === totalPages}
                        className="bg-gray-300 px-4 py-2 rounded"
                    >
                        Next
                    </button>
                </div>
      </div>)
    }


    const cl = "rounded p-2 w-100 m-2 border-1 mb-10";

    // Handle deleting an order
    const handleDelete = async (orderId, order) => {
        showMagniAlert(async () => {
          try {
            await fetch(`${URL_PREFIX}/handleOrders?id=${orderId}`, {
                method: "DELETE",
            });
            fetchOrders();
        } catch (error) {
            console.error("Delete error:", error);
        }
        },'DELETE', 'DELETE ORDER ' + order?.number)
    };

    // Handle editing an order
    const handleEdit = (order) => {
        setShowAdd(true);
        setOrderForm(order);
        setEditingOrderId(order.id);
    };


    const [showAdd, setShowAdd] = useState(false);
    const renderAddOrder = () => {
        if (!showAdd) {
            return (
                <div className="m-10">
                    <FormButton
                        label="+ Add New Order"
                        isPrimary superExtraClass=" bg-success"
                        isFullWidth
                        onClick={() => {
                          setEditingOrderId(null)
                          setOrderForm({})
                          setShowAdd(!showAdd)}}
                    />
                </div>
            );
        }


          
 


        return (
          <div
          className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50"
          style={{ zIndex: 99999999 }}
        >
         <ScreenContainer 
         title='Order Details'
         onBack={() => setShowAdd(false)}>
         
            <div className="p-10">
               

            <span className="fw-bold mx-2">Order#</span>
                <input
                    className={cl}
                    type="text"
                    name="number"
                    value={orderForm.number}
                    placeholder="Order Number"
                    onChange={handleInputChange}
                />
<span className="fw-bold mx-2">Invoice Details</span>
                <textarea
                    className={cl}
                    name="HTML"
                    value={orderForm.HTML}
                    placeholder="Order HTML"
                    onChange={handleInputChange}
                ></textarea>

<hr className="m-2"/>
<span className="fw-bold mx-2">Customer Email</span>
                <input
                    className={cl}
                    type="email"
                    name="email"
                    value={orderForm.email}
                    placeholder="Customer Email"
                    onChange={handleInputChange}
                />

                {/* <div className={cl}>
                    <input
                        type="checkbox"
                        name="isComplete"
                        checked={orderForm.isComplete}
                        onChange={handleInputChange}
                    />
                    &nbsp; Is Complete
                </div> */}

                {/* <div className={cl}>
                    <input
                        type="checkbox"
                        name="isShipped"
                        checked={orderForm.isShipped}
                        onChange={handleInputChange}
                    />
                    &nbsp; Is Shipped
                </div> */}
<span className="fw-bold mx-2">Amazon Order#</span>
                <input
                    className={cl}
                    type="text"
                    name="amazonOrderNumber"
                    value={orderForm.amazonOrderNumber}
                    placeholder="Amazon Order #"
                    onChange={handleInputChange}
                />

<span className="fw-bold mx-2">ESA Order#</span>
                <input
                    className={cl}
                    type="text"
                    name="esaOrderNumber"
                    value={orderForm.esaOrderNumber}
                    placeholder="ESA Order #"
                    onChange={handleInputChange}
                />

                {/* <input
                    className={cl}
                    type="text"
                    name="datePlaced"
                    value={orderForm.datePlaced}
                    placeholder="Date Placed"
                    onChange={handleInputChange}
                /> */}
<span className="fw-bold mx-2 text-success">Profit $</span>
                <input
                    className={cl + ' text-success'}
                    type="text"
                    name="profit"
                    value={orderForm.profit}
                    placeholder="Profit_$"
                    onChange={handleInputChange}
                />

                <FormButton
                loading={loading}
                    isPrimary
                    isFullWidth
                    label={editingOrderId ? "Update Order" : "Add Order"}
                    onClick={handleSubmit}
                />
            </div>
         
            </ScreenContainer>
            </div>

        );
    };

    if(!isAuthenticated) {
        
      return <AuthChecker />
    }



    return (
        <ScreenContainer title={"Orders"}>
            <div className="px-10 pb-20">
                {loading ? (
                    <div className="text-success border-2 text-center m-10 p-2 fs-3">
                        Loading....
                    </div>
                ) : (
                    ""
                )}
                {renderAddOrder()}
                {
                  showModal ? <HTMLRendererModal showModal={showModal} htmlString={formatInvoice(currentHtml)}
                  onClose={() => setShowModal(false)} /> : null
                }
              {renderTable()}
            </div>
        </ScreenContainer>
    );
};



const HTMLRendererModal = ({ htmlString ,showModal, onClose = () => {} }) => {
  return (
    <div
    className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50"
    style={{ zIndex: 99999999 }}
  >
   
    <div
      className="bg-white p-6 rounded-lg shadow-lg max-w-3xl max-h-[90vh] overflow-auto"
      style={{ width: "90%" }}
    >
       <div className="cursor-pointer bg-danger text-white w-[100px] mb-10 border text-center" onClick={onClose}>
      Close
    </div>
      <div>
        <div
          className="overflow-auto"
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      </div>
    </div>
  </div>
  
  );
};

function formatInvoice(invoiceText) {

  if(invoiceText?.includes('<div')) {
    return invoiceText;
  }
  const invoice = {};

    // Extract invoice details
    invoice.invoiceNumber = invoiceText.match(/Invoice number: ([^\n]+)/)?.[1]?.trim();
    invoice.issueDate = invoiceText.match(/Date of issue: ([^\n]+)/)?.[1]?.trim();

    // Extract seller address
    invoice.sellerAddress = invoiceText.match(/New Lesson Plan\s+([\s\S]+?)Bill to/)?.[1]?.trim();

    // Extract buyer details
    invoice. buyerDetails = invoiceText.match(/Bill to\s+([\s\S]+?)\s+New Lesson Plan's/)?.[1]?.trim();


    // Extract kit details
    invoice.kitName = invoiceText.match(/New Lesson Plan's ([^\n]+)/)?.[1]?.trim();
    invoice.kitItems = [...invoiceText.matchAll(/-\s+(.+?)\s+(_https?:\/\/[^\s]+)/g)].map(match => ({
        description: match[1]?.trim(),
        url: match[2]?.replace('_', ''), // Replace leading underscore with nothing for valid URLs
    }));

    // Extract pricing
    invoice.pricing = {
        description: invoiceText.match(/Description Qty Unit price Amount([\s\S]+?)Subtotal:/)?.[1]?.trim(),
        subtotal: invoiceText.match(/Subtotal:\s*\$([\d,.]+)/)?.[1]?.trim(),
        taxes: invoiceText.match(/Taxes:\s*\$([\d,.]+)/)?.[1]?.trim(),
        shipping: invoiceText.match(/Shipping:\s*\$([\d,.]+)/)?.[1]?.trim(),
        total: invoiceText.match(/Total:\s*\$([\d,.]+)/)?.[1]?.trim(),
    };

    // Build the HTML
    const html = `
    <div style="font-family: Arial, sans-serif; line-height: 1.5;">
        <h1>Invoice</h1>
        <p><strong>Invoice Number:</strong> ${invoice.invoiceNumber}</p>
        <p><strong>Date of Issue:</strong> ${invoice.issueDate}</p>
        
       
        
        <div class='border-1 p-2'>
        <h2>Buyer Details</h2>
        <p>${invoice.buyerDetails?.replace(/\n/g, '<br>')}</p>
        </div>
        
        <h2>Kit Details</h2>
        <p><strong>${invoice.kitName}</strong></p>
        <ul>
            ${invoice.kitItems.map(item => `
                <li class='m-10'>
                    ${item.description?.substring(0, 20)} 
                    <a href="${item.url}" class='text-indigo-500' target="_blank">${item.url}</a>
                </li>
            `).join('')}
        </ul>
        
        <h2>Pricing</h2>
        <table border="1" cellspacing="0" cellpadding="5" style="border-collapse: collapse; width: 100%; max-width: 600px;">
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Subtotal</th>
                    <th>Taxes</th>
                    <th>Shipping</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>${invoice.pricing.description || ''}</td>
                    <td>$${invoice.pricing.subtotal}</td>
                    <td>$${invoice.pricing.taxes}</td>
                    <td>$${invoice.pricing.shipping}</td>
                    <td><strong>$${invoice.pricing.total}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>`;

    return html;
}




export const ModalWrapper = ({...props}) => {
    return(<div style={{}}>
         <div
          className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50"
          style={{ zIndex: 99999999}}
        >
            {props.children}
        </div>
    </div>)
}