
export const getVoucherTemplate = (couponCode = "") => {
  return `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Expedited Order Paid</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      background-color: #f4f4f4;
      color: #333333;
      margin: 0;
      padding: 0;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      text-align: center;
    }
    .header {
      background-color: #6a1b9a;
      color: #ffffff;
      padding: 15px;
      font-size: 1.5rem;
      font-weight: bold;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .voucher-code {
      margin: 20px 0;
      padding: 20px;
      font-size: 2rem;
      font-weight: bold;
      color: #333333;
      background-color: #f7f7f7;
      border-radius: 10px;
    }
    .footer {
      margin-top: 20px;
      font-size: 0.9rem;
      color: #666666;
    }
    .footer strong {
      color: #6a1b9a;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">Voucher Details</div>
    <p>Thank you for your purchase! Below is your exclusive voucher code. Keep it safe and redeem it at your convenience:</p>
    <div class="voucher-code">
      ${couponCode}
    </div>
    <div class="footer">
      If you have any questions, feel free to reach out to our <a href='mailto:support@newlessonplan.com'> support team </a>. 
      <br><br>
      Have a wonderful day!<br>
      <strong>NewLessonPlan.com</strong>
    </div>
  </div>
</body>
</html>
`;
};





export const getExpeditedOrderNumberTemplate = (orderNumber = "") => {
  const split = orderNumber?.split?.('##')
  return `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>ORDER NUMBER ${split?.[0]}</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      background-color: #f4f4f4;
      color: #333333;
      margin: 0;
      padding: 0;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      text-align: center;
    }
    .header {
      background-color: #6a1b9a;
      color: #ffffff;
      padding: 15px;
      font-size: 1.5rem;
      font-weight: bold;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .voucher-code {
      margin: 20px 0;
      padding: 20px;
      font-size: 2rem;
      font-weight: bold;
      color: #333333;
      background-color: #f7f7f7;
      border-radius: 10px;
    }
    .footer {
      margin-top: 20px;
      font-size: 0.9rem;
      color: #666666;
    }
    .footer strong {
      color: #6a1b9a;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">Details</div>
    <p>Got an expedited order for Order number:</p>
    <div class="voucher-code">
      ${split?.[0]}
    </div>
    <img src='${split?.[1]}' 
    <div class="footer">
      
      <br><br>
      Have a wonderful day!<br>
      <strong>NewLessonPlan.com</strong>
    </div>
  </div>
</body>
</html>
`;
};
