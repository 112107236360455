export const  generateOrderEmail = (orderNumber, deliveryDate)  => {
    return `
      
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Order Confirmation</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #f3f1fc;
                    margin: 0;
                    padding: 0;
                    color: #333;
                }
                .email-container {
                    max-width: 600px;
                    margin: 30px auto;
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                }
                .email-header {
                    background-color: #6a1b9a;
                    color: white;
                    text-align: center;
                    padding: 20px;
                }
                .email-header h1 {
                    margin: 0;
                    font-size: 24px;
                }
                .email-body {
                    padding: 20px;
                }
                .email-body p {
                    margin: 15px 0;
                    line-height: 1.6;
                }
                .email-footer {
                    background-color: #f3f1fc;
                    color: #6a1b9a;
                    text-align: center;
                    padding: 15px;
                    font-size: 14px;
                }
                a {
                    color: #6a1b9a;
                    text-decoration: none;
                }
                a:hover {
                    text-decoration: underline;
                }
                .button {
                    display: inline-block;
                    margin-top: 20px;
                    padding: 10px 20px;
                    color: white;
                    background-color: #6a1b9a;
                    border-radius: 5px;
                    text-decoration: none;
                    font-size: 16px;
                }
                .button:hover {
                    background-color: #4a148c;
                }
            </style>
        </head>
        <body>
            <div class="email-container">
                <div class="email-header">
                    <h1>Order Confirmation</h1>
                </div>
                <div class="email-body">
                    <p>Hello,</p>
                    
                    <p>We are excited to confirm that your order <strong>#${orderNumber}</strong> has been placed successfully and will be shipped to you shortly.</p>
                    <p>We truly value your trust in us and look forward to serving your next order.</p>
                    <br/>
                    <p class='button'> Estimated Delivery Date ${deliveryDate} </p>
                   
                </div>
                <div class="email-footer">
                    <p>NewLessonPlan.com</p>
                </div>
            </div>
        </body>
        </html>
    `;
}

