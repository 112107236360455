import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/tailwind.css";
import { FormInput } from "./components/formInput";
import { jsPDF } from "jspdf";
import ReCAPTCHA from "react-google-recaptcha";
import { MainBg, MainPage } from "./Home";
import { FormTwoColumnView } from "./components/formTwoColumnView";
import { FormSelect } from "./components/formSelect";
import { CLASS, COUPON_USAGE_DETAILS, DISABILITY, GRADE, IS_TESTING_LOCALLY_WITH_NO_AI, MATERIALS, SAVE_ITEM_KEY, showToast, SUBJECT } from "./constants";
import FormAutocompleteDropdown from "./components/formAutoComplete";
import DataModel from "./DataModel";
import { FormButton } from "./components/formButton";
import alertify from "alertifyjs";
import { Docky } from "./Docky";
import { FormCheckbox } from "./components/formCheckbox";
import { FormLabel } from "./components/FormLabel";
import SwitchControl from "./components/SwitchControl";
import { handleApplyCoupon } from "./utils/vGenerator";
import { ScreenContainer } from "./App";



export const NewCurriculum = () => {
  const captchaRef = React.useRef(null);
  const [name, setName] = React.useState('')
  const [applicationNumber, setsetApplicationNumber] = React.useState('')
  const [subject, setSubject] = React.useState('')
  const [materials, setMaterials] = React.useState([])
  const [grade, setGrade] = React.useState('')
  const [_class, _setClass] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [didResolveCaptcha, setDidResolveCaptcha] = React.useState(false)
  const [getAsJson, setGetAsJson] = React.useState(false)
  const [hasDisability, setHasDisability] = React.useState(false)
  const [disability, setDisability] = React.useState('');
  const [email, setEmail] = React.useState('support@newLessonPlan.com')
  const [showCouponInput, setShowCouponInput] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState('');
  const [isUsingCoupon, setIsUsingCoupon] = React.useState(false)


  return (<>
    <ScreenContainer hideBackButton title={'Create Your Curriculum'}>
    <div className="relative isolate ">
  
  <div className="mt-3">
    
    <div className="text-center">
   
    <h3 style={{maxWidth:'80%', alignSelf:'center', margin: '0 auto', color: 'gray'}}>
    We make it simple to get an ESA curriculum. Don't risk rejection with generic AI-generated curriculums.
      </h3>
      
    </div>

    <div className="m-20">
  
  

    {getAsJson ? null :   <div className="row">
      <div className="col-lg-6">
      <FormInput label="Student Full Name" autoFocus
      placeholder="" text={name} onTextChange={t => setName(t)}  />
      </div>
      <div className="col-lg-6">
      <FormInput label="ESA Application Number"  text={applicationNumber} onTextChange={t => setsetApplicationNumber(t)}/>
      </div>
      </div>}
      <div className="row  mb-10 cursor-pointer text-center"
  
  >
      <div className="col-md-12 flex rounded"
       
      >
      <FormCheckbox label="Click if you don't want to provide student name/application number " 
      value={getAsJson} 
       onChange={v => {setGetAsJson(v)}}/>
        
         <div className=" underline text-left"
         style={{textDecorationColor: 'gray'}}
       onClick={() => {
        alertify.alert('Info', `
          if you provide name/application Id, you will get a PDF that you simply drag and drop onto ClassWallet.
          But if you prefer to add student name and application number yourself, we will give you a Word document that you can edit, save, and then drop onto ClassWallet.`)
       }}
       >
        <span className="text-gray-500" style={{fontSize: 14, color: 'gray'}}>
        What's the difference?
        </span>
        </div>
      </div>
     
       
    </div>


    
      {/* <div className="row">
      <div className="col-lg-6">
      <FormInput label="Subject"  text={subject} onTextChange={t => setSubject(t)}/>
      </div>
      <div className="col-lg-6">
      
      </div>
      </div> */}


      <div className="row">
      <div className="col-lg-6">
      <FormSelect _options={SUBJECT.map(f => ({label: f, value:f}))} label="Subject" onSelect={e => setSubject(e)}   />
      </div>
      <div className="col-lg-6">
      <FormSelect _options={GRADE.map(f => ({label: f, value:f}))} label="Grade" onSelect={e => setGrade(e)}  placeholder="Select grade..."  />
      </div>
      </div>

      <div className=" mb-12">
      <div className="row mt-1 mb-4">
        <div className="row">
        <SwitchControl checked={hasDisability} onToggle={() => setHasDisability(!hasDisability)}/>
        </div>
          </div>
    {!hasDisability ? null : <div className="col-lg-12">  <FormAutocompleteDropdown allowNewValues  _selectedTags={DISABILITY?.map(f=>({name: f}))} 
      placeholder={disability?.length ? '' :  "Click to add a disability or type your own and press 'Enter'"}
      onGetSelectedValues={e => setDisability(e)}
      /></div>}
  </div>
     
      <div className="row mb-20">
    <div className="form-label fw-bold">Materials</div>
    <FormAutocompleteDropdown 
    placeholder="Click to add materials or type your own and press 'Enter'."
    allowNewValues  _selectedTags={MATERIALS?.map(f=>({name: f}))} 
      
      onGetSelectedValues={e => setMaterials(e)}
      />
<small 
className="inline-block  p-1  rounded-md text-gray-700 mt-3 border p-3 max-w-[99%] "
style={{ fontWeight: 'bold', background: 'rgba(255, 217, 102,.2)' }}
>
<span 
  className=" items-center"
>

  IMPORTANT: For books & board games type: <span className="font-semibold">Book - Title</span> (e.g. Book - Tale of Two Cities, Board game - Monopoly ) to avoid rejection.

</span>
{/* <span className="text-danger ml-2">Avoid putting in just 'books' or 'board game', type in the book/game name.</span>   */}
</small>

  </div>

      {/* <ReCAPTCHA
        ref={captchaRef}
        sitekey={`6LccdQsqAAAAAPjYyZna8d_l666BsSYLblpoVGhP`}
        onChange={() => {
          //enable main button
          setDidResolveCaptcha(true)
        }}
      /> */}


      {/* <div className="col-lg-6 ">
      <FormInput label="Email to send the curriculum to" 
      text={email} onTextChange={t => setEmail(t)}/>
      </div> */}

      {/* Coupon Code Section */}
      <div className="m-4 text-center">
          <button className="text-indigo-600 underline" onClick={() => setShowCouponInput(!showCouponInput)}>
            {showCouponInput ? 'Remove Voucher Code' : 'Have a Voucher Code?'}
          </button>
        </div>
        {showCouponInput && (
          <div className="row mt-2">
            <div className="col-md-12">
              <FormInput 
              
              placeholder="Enter voucher code" text={couponCode} onTextChange={t => setCouponCode(t)} />
            </div>
          </div>
        )}


          <FormButton
          disabled={loading || isUsingCoupon}
          loading={loading && !isUsingCoupon}
          label={isUsingCoupon ? 'Voucher applied successfully. Please wait...' :  "Pay 0.99 & Download Curriculum"} isFullWidth superExtraClass={`p-3 bg-${isUsingCoupon ? 'success': 'indigo-600'}`}
           onClick={async () => {

            let useCouponToPay = false;
            if(((!name?.length || !applicationNumber?.length) && !getAsJson) || !subject?.length || !grade?.length || !materials?.length) {
              alertify.alert('Missing Info', 'All fields are required. Please fill out missing fields')
              return;
            }

            if(hasDisability && !disability?.length) {
              alertify.alert('Missing Info', 'Enter type of disability or toggle disability off to continue.')
              return;
            }

            if(!email?.length || !validateEmail(email)) {
              alertify.alert('Invalid Email', 'Please enter a valid email to send the curriculum to.')
              return;
            }
            setLoading(true)
            
            if(couponCode?.length) {
              //validate 
              const result = await handleApplyCoupon(couponCode?.trim()?.replaceAll(' ', ''))
              useCouponToPay = result?.isSuccess;
              if(useCouponToPay) {
                setIsUsingCoupon(true)
                sessionStorage.setItem(COUPON_USAGE_DETAILS, JSON.stringify(result))
              } else {
                alertify.alert("Invalid", "Invalid voucher code.")
                setLoading(false)
                return;
              }
            }

            // if(!didResolveCaptcha) {
            //   alertify.alert('Captcha', `Please check "I'm not a robot box" to continue`);
            //   return;
            // }
            setLoading(true);
            const data = {studentName: name, applicationNumber: applicationNumber, subject: subject, grade: grade, materials: materials?.join(', '), disability: hasDisability && disability?.length ? disability?.join(', ') : '', getAsJson: getAsJson, email: email};
            //save data 
            sessionStorage.setItem(SAVE_ITEM_KEY, JSON.stringify(data))
            if(IS_TESTING_LOCALLY_WITH_NO_AI || useCouponToPay) {
              showToast("Voucher applied successfully")
              setTimeout(() => {
                 window.location.href = '/success'
              }, 2000);
            return;
            }
            //redirect to payment
            window.location.href = global.isDev ?  'https://buy.stripe.com/test_eVa2bPfAYgvFe5y6oo' :
            'https://buy.stripe.com/4gwcNG1iq1Ot4SI8ww'
          
          
          }}
          />

      
      <div className="text-center mt-20 text-gray-500 px-20">
      <hr/>
      <small>Once you click the above button, you'll be redirected to pay $0.99 or if you entered a valid voucher code, download will start right after. Once payment is complete your curriculum will download to your device.</small>
      </div>
    </div>
  </div>
  </div>
    </ScreenContainer>
    </>
  );
};


export function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}
